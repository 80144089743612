// extracted by mini-css-extract-plugin
export var add_to_cart = "AddToCart-module--add_to_cart--15e8d";
export var added_to_cart = "AddToCart-module--added_to_cart--f7df8";
export var btn = "AddToCart-module--btn--8b7c2";
export var btnPrimary = "AddToCart-module--btn-primary--8c446";
export var cart = "AddToCart-module--cart--82a0e";
export var loading_cart = "AddToCart-module--loading_cart--4d230";
export var loading_cart_button = "AddToCart-module--loading_cart_button--52a85";
export var minus = "AddToCart-module--minus--7beb6";
export var placeHolderShimmer = "AddToCart-module--placeHolderShimmer--45a4e";
export var plus = "AddToCart-module--plus--c89a2";