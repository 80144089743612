import React, { useContext, useEffect, useState } from "react"
import { MdAddShoppingCart } from "react-icons/md"
import { toast } from "react-toastify"
import * as styles from "./AddToCart.module.scss"
import { CartContext } from "../../../contexts/Cart"
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai"
import { HiOutlineCheck } from "react-icons/hi"

const min = 1
const max = 50

const AddToCart = ({
  id = "",
  name = "",
  amount = 0,
  setAmount = () => null,
  unitAmount = "",
  isLoading = true,
}) => {
  const {
    cart: { items },
    actions: { addToCart },
  } = useContext(CartContext)

  const [itemExists, setIe] = useState(false)

  const add = () => {
    toast.success(`Added ${name} ${unitAmount} to the cart`)
    if (items.find(item => item.id === id)) {
      // existing product, update the amount
      addToCart({ id, amount })
    } else {
      addToCart({ id, amount })
    }
  }

  useEffect(() => {
    const itemExists = items.find(item => item.id === id)
    setIe(itemExists && itemExists.amount === amount)
  }, [items, amount, id])

  return (
    <div
      className={`${styles.add_to_cart} ${
        isLoading ? styles.loading_cart : ""
      }`}
    >
      <button
        label={"Reduce"}
        className={`btn ${styles.minus}`}
        onClick={() => setAmount(amount > min ? amount - 1 : 0)}
        disabled={amount <= min}
      >
        <AiOutlineMinus />
      </button>
      <span>{amount}</span>
      <button
        label={"Add"}
        className={`btn ${styles.plus}`}
        onClick={() => setAmount(amount < max ? amount + 1 : max)}
        disabled={amount >= max}
      >
        <AiOutlinePlus />
      </button>
      <button
        className={`btn ${styles.cart} ${
          isLoading ? styles.loading_cart_button : ""
        }`}
        onClick={add}
      >
        <MdAddShoppingCart />
        {itemExists && <HiOutlineCheck className={styles.added_to_cart} />}
      </button>
    </div>
  )
}
export default AddToCart
