import React, { useContext, useState } from "react"
import { Link } from "gatsby"
import * as styles from "./_sidebar.module.scss"
import { StoreContext } from "../../contexts/Store"
import { RiSearchLine } from "react-icons/ri"
import Loading from "../../components/Loading"

const SideBar = ({
  search = () => null,
  type = "all",
  onClick = () => null,
}) => {
  const [searchStr, setSearchStr] = useState("")
  const {
    store: { products, categories },
  } = useContext(StoreContext)

  const handleSearch = e => {
    if (e.keyCode === 13) {
      search(searchStr)
      onClick()
    }
  }
  return (
    <>
      {type !== "categories" && (
        <>
          <h5 className={`${styles.title} ${type !== "all" ? "mt-5" : ""}`}>
            Search
          </h5>
          <div className={styles.search_box_wrapper}>
            <input
              type="text"
              className="form-control mb-5"
              placeholder={"type and hit enter"}
              onChange={e => setSearchStr(e.target.value)}
              onKeyDown={handleSearch}
            />
            <button
              className="btn btn-primary"
              onClick={() => {
                onClick()
                search(searchStr)
              }}
            >
              <RiSearchLine />
            </button>
          </div>
        </>
      )}
      {type !== "search" && (
        <>
          <h5 className={`${styles.title} ${type !== "all" ? "mt-5" : ""}`}>
            Categories
          </h5>
          {categories.length === 0 ? (
            <Loading />
          ) : (
            <>
              <ul className={styles.list}>
                <li>
                  <Link
                    to={`/organic-store`}
                    onClick={onClick}
                    activeClassName={"store-active-category"}
                  >
                    <span>All</span>
                    <span>({products.length})</span>
                  </Link>
                </li>
                {categories.map(({ slug, name }) => {
                  return (
                    <li key={slug}>
                      <Link
                        to={`/organic-store/${slug}`}
                        onClick={onClick}
                        activeClassName={"store-active-category"}
                      >
                        <span>{name}</span>
                        <span>
                          (
                          {
                            products.filter(p => p.category.slug === slug)
                              .length
                          }
                          )
                        </span>
                      </Link>
                    </li>
                  )
                })}
              </ul>
            </>
          )}
        </>
      )}
    </>
  )
}
export default SideBar
