import React from "react"

const Loading = ({ isLight = false }) => (
  <span
    className={`spinner-border spinner-border-sm ${
      isLight ? "text-light" : "text-primary"
    }`}
    role="status"
    aria-hidden="true"
  />
)

export default Loading
