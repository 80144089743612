import React, { useContext, useEffect, useState } from "react"
import { StoreContext } from "../../../contexts/Store"
import Product from "../Product"
import Loading from "../../Loading"
import { Link } from "gatsby"
import * as s from "./index.module.scss"

const allCategories = "All Categories"
const perPage =
  typeof window !== "undefined" && window.innerWidth < 576 ? 10 : 20

const ProductsList = ({
  activeCategory = null,
  showCategoriesMobileMenu = () => null,
}) => {
  const [categoryName, setCategoryName] = useState(allCategories)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)

  const {
    store: { products, filteredProducts, categories, searchString },
    actions: { setFilteredProducts, setSearchString },
  } = useContext(StoreContext)

  useEffect(() => {
    let filtered = products
    setSearchString("")
    if (activeCategory) {
      filtered = products.filter(p => p.category.slug === activeCategory)
    }
    setFilteredProducts(filtered)

    setTotalPages(Math.ceil(filtered.length / perPage))
    setCurrentPage(1)

    if (activeCategory) {
      // find the cat
      const category = categories.find(cat => cat.slug === activeCategory)
      if (category) {
        setCategoryName(category.name)
      } else {
        setCategoryName(allCategories)
      }
    } else {
      setCategoryName(allCategories)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeCategory, products])

  useEffect(() => {
    setTotalPages(Math.ceil(filteredProducts.length / perPage))
    setCurrentPage(1)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredProducts])

  if (products.length === 0) {
    return (
      <div className="col-sm-12 text-center">
        <Loading />
      </div>
    )
  }

  if (filteredProducts.length === 0) {
    return (
      <div className="col-sm-12">
        <p>No products found.</p>
        <ul>
          <li>Please select a different category.</li>
          <li>Please use different words for search</li>
          <li>
            Or go to our <a href={"https://store.owita.lk"}>Organic Store</a>
          </li>
        </ul>
      </div>
    )
  }

  return (
    <>
      <div className="col-sm-12 d-flex align-items-start justify-content-between mt-2 mb-4">
        <p>
          Displaying {filteredProducts.length} product
          {filteredProducts.length === 1 ? "" : "s"}{" "}
          {searchString ? "for" : "in"} <br className={"d-lg-none"} />{" "}
          {searchString ? (
            <b>"{searchString}"</b>
          ) : (
            <span
              className={s.categories_trigger}
              onClick={() => {
                if (typeof window !== "undefined" && window.innerWidth < 768) {
                  showCategoriesMobileMenu()
                }
              }}
            >
              {categoryName}
            </span>
          )}
        </p>
        <p>
          Page {currentPage} of {totalPages}
        </p>
      </div>
      {filteredProducts
        .slice((currentPage - 1) * perPage, currentPage * perPage)
        .map(product => {
          return <Product product={product} key={product.id} />
        })}

      <div className="col-sm-12  mt-4">
        <div
          className={
            "d-flex flex-column flex-lg-row justify-content-center align-items-center justify-content-lg-between"
          }
        >
          <Link
            to={"/price-list"}
            className={"mt-4 mb-0 order-2 order-lg-1 my-lg-0"}
          >
            View full price list
          </Link>
          <div className="btn-group order-1 order-lg-2" role="group">
            <button
              type="button"
              className="btn btn-primary"
              disabled={currentPage <= 1}
              onClick={() => {
                setCurrentPage(currentPage > 1 ? currentPage - 1 : 1)
                window.scrollTo(0, 0)
              }}
            >
              Prev
            </button>
            <span className="btn btn-light">
              Page {currentPage} of {totalPages}
            </span>
            <button
              type="button"
              className="btn btn-primary"
              disabled={currentPage >= totalPages}
              onClick={() => {
                setCurrentPage(
                  currentPage < totalPages ? currentPage + 1 : currentPage
                )
                window.scrollTo(0, 0)
              }}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default ProductsList
